import React from 'react';
import { graphql } from 'gatsby';
import { getKeyedResources } from '../../utils/resource-helper';
import Layout from '../../components/Layout';
import { Container, Row, Column } from '../../components/Grid';
import Section from '../../components/Section';
import Quicklinks from '../../components/Quicklinks';
import Quote from '../../components/Quote';
import Hero from '../../components/Hero';
import Resource from '../../components/Resource';
import AdditionalResources from '../../components/AdditionalResources';
import ToggleClassOnVisible from '../../components/ToggleClassOnVisible';
import { useTranslation } from 'react-i18next';

const EducatorsActivities = ({ data, pageContext }) => {
  const { t } = useTranslation(['educators', 'common', 'paths']);

  const {
    nutrientWise,
    collectingClassroomData,
    whatAreTheOdds,
    exerciseForHealth,
    exerciseForHealthCompanionEdGuide,
    nutritiousEating,
    nutritiousEatingCompanionEdGuide,
  } = getKeyedResources(data);

  const fadeInDelays = [0, 300, 600];

  const textOverlay = (
    <div className="educators__text_overlay_container">
      <Container fullWidth={true}>
        <div className="educators__text_overlay_inner_container">
          <div className="educators__text_overlay_section text-bold color-yellow">
            {t('heroSection')}
          </div>
          <span className="educators__text_overlay_title">
            {t('heroTitle1')}{' '}
          </span>
          <span className="educators__text_overlay_title2 text-bold color-light-blue">
            {t('heroTitle2')}
          </span>
        </div>
      </Container>
    </div>
  );

  const heroImages = [
    { image: 'img-lead-educator-1.jpg', text: '', title: '' },
    { image: 'img-lead-educator-2.jpg', text: '', title: '' },
    { image: 'img-lead-educator-3.jpg', text: '', title: '' },
    { image: 'img-lead-educator-4.jpg', text: '', title: '' },
  ];

  return (
    <Layout title="Educators" className={pageContext.lang}>
      <Hero mediaSource={heroImages} textOverlay={textOverlay} />
      <Section>
        {/* INTRO AND QUICK LINKS */}
        <Container>
          <Row>
            <Column size={9}>
              <h2 className="text-bold">
                {t('activitiesIntroHeading').toUpperCase()}
              </h2>
              <p className="activities__intro-body">
                {t('activitiesIntroBody')}
              </p>
              <Quicklinks
                className="text-sans-serif"
                title={t('activitiesIntroQuickLinksLabel').toUpperCase()}
                smoothScroll={true}
                links={[
                  {
                    target: '#self-paced-modules',
                    label: t('activitiesIntroQuickLinksLink1').toUpperCase(),
                  },
                  {
                    target: '#ready-to-use-activities',
                    label: t('activitiesIntroQuickLinksLink2').toUpperCase(),
                  },
                ]}
              />
            </Column>
          </Row>
        </Container>
      </Section>
      <Section className="bg-color-purple" id="self-paced-modules">
        {/* SELF PACED MODULES */}
        <Container>
          <ToggleClassOnVisible extraClasses="appear-on-visible">
            <Row>
              <Column size={9}>
                <h2 className="color-white">
                  {t('activitiesModulesHeading').toUpperCase()}
                </h2>
                <p className="color-white activities__self-paced-modules-body mb-1">
                  {t('activitiesModulesBody')}
                </p>
              </Column>
            </Row>
          </ToggleClassOnVisible>
          <ToggleClassOnVisible extraClasses="appear-on-visible">
            <Row className="bg-color-white rounded-corners pr-1.5 pl-1.5 pt-2">
              <Column size={7}>
                <Resource
                  title={exerciseForHealth.title}
                  duration={exerciseForHealth.duration}
                  description={exerciseForHealth.description}
                  img={exerciseForHealth.img}
                  actions={exerciseForHealth.actions}
                  withBorder={false}
                />
              </Column>
              <Column size={5}>
                <Resource
                  title={exerciseForHealthCompanionEdGuide.title}
                  description={exerciseForHealthCompanionEdGuide.description}
                  img={exerciseForHealthCompanionEdGuide.img}
                  actions={exerciseForHealthCompanionEdGuide.actions}
                  alignedButtons={false}
                  withBorder={false}
                />
              </Column>
            </Row>
          </ToggleClassOnVisible>
          <div className="pt-3" />
          <ToggleClassOnVisible extraClasses="appear-on-visible">
            <Row className="bg-color-white rounded-corners pr-1.5 pl-1.5 pt-2">
              <Column size={7}>
                <Resource
                  title={nutritiousEating.title}
                  duration={nutritiousEating.duration}
                  description={nutritiousEating.description}
                  img={nutritiousEating.img}
                  actions={nutritiousEating.actions}
                  withBorder={false}
                />
              </Column>
              <Column size={5}>
                <Resource
                  title={nutritiousEatingCompanionEdGuide.title}
                  description={nutritiousEatingCompanionEdGuide.description}
                  img={nutritiousEatingCompanionEdGuide.img}
                  actions={nutritiousEatingCompanionEdGuide.actions}
                  alignedButtons={false}
                  withBorder={false}
                />
              </Column>
            </Row>
          </ToggleClassOnVisible>
        </Container>
      </Section>
      <Section id="ready-to-use-activities">
        {/* READY TO USE ACTIVITIES */}
        <Container>
          <ToggleClassOnVisible extraClasses="appear-on-visible">
            <h2>{t('activitiesReadyToUseHeading')}</h2>
            <p className="activities__ready-to-use-activities-body mb-1">
              {t('activitiesReadyToUseBody')}
            </p>
          </ToggleClassOnVisible>
          <Row>
            <Column size={4}>
              <ToggleClassOnVisible
                delay={fadeInDelays[0]}
                extraClasses="appear-on-visible"
                style={{ height: '100%' }}
              >
                <Resource
                  tophat={collectingClassroomData.tophat}
                  title={collectingClassroomData.title}
                  duration={collectingClassroomData.duration}
                  description={collectingClassroomData.description}
                  img={collectingClassroomData.img}
                  actions={collectingClassroomData.actions}
                />
              </ToggleClassOnVisible>
            </Column>
            <Column size={4}>
              <ToggleClassOnVisible
                delay={fadeInDelays[1]}
                extraClasses="appear-on-visible"
                style={{ height: '100%' }}
              >
                <Resource
                  tophat={nutrientWise.tophat}
                  title={nutrientWise.title}
                  duration={nutrientWise.duration}
                  description={nutrientWise.description}
                  img={nutrientWise.img}
                  actions={nutrientWise.actions}
                />
              </ToggleClassOnVisible>
            </Column>
            <Column size={4}>
              <ToggleClassOnVisible
                delay={fadeInDelays[2]}
                extraClasses="appear-on-visible"
                style={{ height: '100%' }}
              >
                <Resource
                  tophat={whatAreTheOdds.tophat}
                  title={whatAreTheOdds.title}
                  duration={whatAreTheOdds.duration}
                  description={whatAreTheOdds.description}
                  img={whatAreTheOdds.img}
                  actions={whatAreTheOdds.actions}
                />
              </ToggleClassOnVisible>
            </Column>
          </Row>
        </Container>
      </Section>
      <Section>
        {/* ADDITIONAL RESOURCES */}
        <ToggleClassOnVisible extraClasses="appear-on-visible">
          <Container>
            <h2 className="h3 text-serif">
              {t('common:exploreAdditionalResources')}
            </h2>
            <Row>
              <Column size={6}>
                <AdditionalResources
                  img="img-addl-resources-about-the-program.png"
                  heading={t('common:aboutTheProgram')}
                  to={t('paths:/about/').path}
                />
              </Column>
              <Column size={6}>
                <AdditionalResources
                  img="img-addl-resources-employees.png"
                  heading={t('common:exploreFamilyResources')}
                  to={t('paths:/families/').path}
                />
              </Column>
            </Row>
          </Container>
        </ToggleClassOnVisible>
      </Section>
      <ToggleClassOnVisible extraClasses="appear-on-visible">
        <Quote
          quote={t('activitiesSubfooterQuoteBody')}
          author={t('activitiesSubfooterQuoteName')}
          authorDescription={t('activitiesSubfooterQuoteSchoolAndTitle')}
          authorLocation={t('activitiesSubfooterQuoteLocation')}
        />
      </ToggleClassOnVisible>
    </Layout>
  );
};

export const query = graphql`
  query EducatorsActivitiesResources($lang: String, $page: [String]) {
    allResourcesJson(
      filter: { pages: { in: $page }, language: { eq: $lang } }
    ) {
      edges {
        node {
          slug
          tophat
          img
          icon
          subject
          title
          audience
          duration
          description
          actions {
            label
            metadata
            module
            download
            noIcon
            asLink
            linkFromImg
            inverse
            moduleName
          }
          horizontal
          className
          language
          pages
        }
      }
    }
  }
`;

export default EducatorsActivities;
